import { compose, createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { createHashHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "../reducers";

export const history = createHashHistory();

const initialAuth = {
  isSignedIn: false,
  isLoading: false,
  currentUser: null,
  currentAccount: null,
  accounts: [],
};

const loadState = () => {
  try {
    const access_token = localStorage.getItem("access-token");
    const currentAccount = localStorage.getItem("currentAccount");
    if (access_token) {
      return {
        auth: {
          ...initialAuth,
          isSignedIn: true,
          isLoading: true,
          currentAccount: currentAccount,
        },
      };
    } else {
      return { auth: initialAuth };
    }
  } catch (err) {
    console.log(err);
    return { auth: initialAuth };
  }
};

const initialState = loadState();

const middlewares = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === `development`) {
  middlewares.push(logger);
}

export default function configureStore() {
  return createStore(
    createRootReducer(history), // root reducer with router state
    initialState,
    applyMiddleware(...middlewares)
  );
}
