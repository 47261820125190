// redux-token-auth-config.js
import axios from "axios";
import { validateUser } from "../reducers/auth";

export default (store) => {
  const { isSignedIn } = store.getState().auth;
  if (isSignedIn) {
    store.dispatch(validateUser());
  }

  axios.interceptors.request.use((config) => {
    config.headers = {
      ...config.headers,
      "access-token": localStorage.getItem("access-token"),
      client: localStorage.getItem("client"),
      uid: localStorage.getItem("uid"),
    }
    return config;
  }, error => Promise.reject(error));

  axios.interceptors.response.use((response) => {
    if (response.headers["access-token"]){
      localStorage.setItem("access-token", response.headers["access-token"]);
      localStorage.setItem("client", response.headers["client"]);
      localStorage.setItem("uid", response.headers["uid"]);
    }
    return response;
  }, error => {
    if (error.response && error.response.status === 401 && window.location.hash !== '#/signin'){
      window.location.reload();
    }
    return Promise.reject(error);//{ errors: error.response.data.errors };
  });
}
