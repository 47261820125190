import React, {Fragment, Component, FC, useState} from 'react';
import {Col, Row, Button, Form, FormGroup, Input} from 'reactstrap';
import { connect } from 'react-redux';
import { signInUser } from '../../reducers/auth';

type TUserData = {
  email: string; 
  password: string; 
}

interface ILoginBoxedProps {
  errors: any;
  isLoading: boolean;
  signUser: (user: any) => void;
}

const LoginBoxed: FC<ILoginBoxedProps> = ({ errors, isLoading, signUser }) => {
  const [userData, setUserData] = useState<TUserData>({ email: '', password: '' });

  const submitForm = async () => {
    localStorage.clear();
    signUser(userData);
  }

  return (<div className="h-100 bg-plum-plate bg-animation">
    <div className="d-flex h-100 justify-content-center align-items-center">
      <Col md="8" className="mx-auto app-login-box">
          <div className="modal-dialog w-100 mx-auto">
            <div className="modal-content">
                <div className="modal-body">
                    <div className="app-logo mx-auto mb-3"/>
                      <div className="h5 modal-title text-center">
                      </div>
                      <Row form>
                          <Col md={12}>
                              <FormGroup>
                                  <Input
                                    value={userData.email}
                                    onChange={(e) => setUserData((uData: TUserData) => { 
                                      return { ...uData, email: e.target.value }
                                    })}
                                    type="email" name="email" id="email"
                                    placeholder="Email..."/>
                              </FormGroup>
                          </Col>
                          <Col md={12}>
                              <FormGroup>
                                  <Input value={userData.password}
                                    onChange={(e) => setUserData((uData: TUserData) => {
                                      return { ...uData, password: e.target.value }
                                    })}
                                    onKeyDown = {(e) => {
                                      if(e.key === "Enter") {
                                        submitForm();
                                      }
                                    }}
                                    type="password" name="password" id="password"
                                    placeholder="Password..."/>
                              </FormGroup>
                          </Col>
                      </Row>
                    <div className="text-center">
                      { errors.map((e :any,i: number) => <div key={`e${i}`} className="text-danger">{ e }</div>) }
                    </div>
                </div>
                <div className="modal-footer clearfix">
                    <div className="float-right">
                        <Button color="primary" disabled={isLoading} size="lg" onClick={submitForm}>Login</Button>
                    </div>
                </div>
            </div>
          </div>
      </Col>
    </div>
  </div>)
};

const mapStateToProps = (state: any) => ({
  errors: state.auth.errors ? state.auth.errors : [],
  isLoading: state.auth.isLoading
});

const mapDispatchToProps = (dispatch: any) => ({
  signUser: (user: any) => dispatch(signInUser(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginBoxed);