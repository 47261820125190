import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment, useEffect } from "react";
import Loader from "react-loaders";
import Loading from "../../utils/Loading";

import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import SignIn from "../../Pages/SignIn";

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// const UserPages = lazy(() => import("../../DemoPages/UserPages"));
// const Applications = lazy(() => import('../../DemoPages/Applications'));
// const Dashboards = lazy(() => import("../../DemoPages/Dashboards"));

const Domains = lazy(() => import("../../Pages/Domains"));
const ControlRoom = lazy(() => import("../../Pages/ControlRoom"));
const Tools = lazy(() => import("../../Pages/Tools"));
const Content = lazy(() => import("../../Pages/Content"));
const Series = lazy(() => import("../../Pages/Series"));
const Logs = lazy(() => import("../../Pages/Logs"));
const Users = lazy(() => import("../../Pages/Users"));
const Transcoders = lazy(() => import("../../Pages/Transcoders"));
const StreamTargets = lazy(() => import("../../Pages/StreamTargets"));
const Account = lazy(() => import("../../Pages/Account"));
const Venues = lazy(() => import("../../Pages/Venues"));
const Home = lazy(() => import("../../Pages/Home"));
const Passes = lazy(() => import("../../Pages/Passes"));
const FullAccess = lazy(() => import("../../Pages/FullAccess"));
const Coupons = lazy(() => import("../../Pages/Coupons"));
const Payments = lazy(() => import("../../Pages/Payments"));
const Marketing = lazy(() => import("../../Pages/Marketing"));
const Campaigns = lazy(() => import("../../Pages/Campaigns"));
const Balance = lazy(() => import("../../Pages/Balance"));
const Sales = lazy(() => import("../../Pages/Sales"));
const Customers = lazy(() => import("../../Pages/Customers"));
const Bookmarks = lazy(() => import("../../Pages/Bookmarks"));
const Platform = lazy(() => import("../../Pages/Platform"));
const Announcements = lazy(() => import("../../Pages/Annoucements"));
const Recordings = lazy(() => import("../../Pages/Recordings"));
const Submissions = lazy(() => import("../../Pages/Submissions"));
const Guides = lazy(() => import("../../Pages/Guides"));
const Tags = lazy(() => import("../../Pages/Tags"));
const AdminRecordigns = lazy(() => import("../../Pages/Admin/Recordings"));

/*
const requireSignIn = (Component) =>
  compose(
    withRouter,
    connect((state) => ({ auth: state.auth }))
  )(({ auth, ...props }) => {
    useEffect(() => {
      if (!auth.isSignedIn && !auth.isLoading) props.history.push("/signin");
    }, [auth]);
    if (auth.isLoading) {
      return <Loading />;
    } else {
      return auth ? <Component {...props} /> : null;
    }
  });
*/
// const PrivateRoute = ({component: Component, ...rest }) => (
//   <Route {...rest} render={(props) => <Redirect to='/signin'/>}/>
// );

const PrivateRoute = connect((state) => ({
  auth: state.auth,
}))(({ component: Component, auth: auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth.isLoading ? (
        <Loading />
      ) : auth.isSignedIn ? (
        <Component {...props} />
      ) : (
        <Redirect to="/signin" />
      )
    }
  />
));

const AppMain = (props) => {
  return (
    <Fragment>
      {/* Components */}
      {/*
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
              <h6 className="mt-5">
                Please wait while we load all the Components examples
                <small>
                  Because this is a demonstration we load at once all the
                  Components examples. This wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/components" component={Components} />
      </Suspense> */}

      {/* Forms */}

      {/* <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
              <h6 className="mt-5">
                Please wait while we load all the Forms examples
                <small>
                  Because this is a demonstration we load at once all the Forms
                  examples. This wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/forms" component={Forms} />
      </Suspense> */}

      {/* Charts */}

      {/* <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-rotate" />
              </div>
              <h6 className="mt-3">
                Please wait while we load all the Charts examples
                <small>
                  Because this is a demonstration we load at once all the Charts
                  examples. This wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/charts" component={Charts} />
      </Suspense> */}

      {/* Tables */}

      {/* <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
              <h6 className="mt-5">
                Please wait while we load all the Tables examples
                <small>
                  Because this is a demonstration we load at once all the Tables
                  examples. This wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/tables" component={Tables} />
      </Suspense> */}

      {/* Elements */}
      {/*
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale" />
              </div>
              <h6 className="mt-3">
                Please wait while we load all the Elements examples
                <small>
                  Because this is a demonstration we load at once all the
                  Elements examples. This wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/elements" component={Elements} />
      </Suspense> */}

      {/* Pages */}

      {/* <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale-party" />
              </div>
              <h6 className="mt-3">
                Please wait while we load all the Pages examples
                <small>
                  Because this is a demonstration we load at once all the Pages
                  examples. This wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/pages" component={UserPages} />
      </Suspense> */}

      {/* Applications */}

      {/* <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse" />
              </div>
              <h6 className="mt-3">
                Please wait while we load all the Applications examples
                <small>
                  Because this is a demonstration we load at once all the
                  Applications examples. This wouldn't happen in a real live
                  app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/apps" component={Applications} />
      </Suspense> */}

      {/* Dashboards */}

      {/* <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-grid-beat" />
              </div>
              <h6 className="mt-3">
                Please wait while we load all the Dashboards examples
                <small>
                  Because this is a demonstration, we load at once all the
                  Dashboards examples. This wouldn't happen in a real live app!
                </small>
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/dashboards" component={Dashboards} />
      </Suspense> */}

      {/* Producer Section */}
      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/control-room" component={ControlRoom} />
      </Suspense>
      
      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/tools" component={Tools} />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/content" component={Content} />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/shows" component={Series} />
      </Suspense>

      <Suspense fallback={<Loading/>}>
        <PrivateRoute path="/bookmarks" component={Bookmarks} />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/passes" component={Passes} />
      </Suspense>
      
      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/tags" component={Tags} />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/admin/recordings" component={AdminRecordigns} />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/full-access" component={FullAccess} />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/coupons" component={Coupons} />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/campaigns" component={Campaigns} />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/payments" component={Payments} />
      </Suspense>
      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/balance" component={Balance} />
      </Suspense>
      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/sales" component={Sales} />
      </Suspense>
      {/* Configuration Section */}
      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/domains" component={Domains} />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/logs" component={Logs} />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/users" component={Users} />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/transcoders" component={Transcoders} />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/stream_targets" component={StreamTargets} />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/account" component={Account} />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/venues" component={Venues} />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/marketing" component={Marketing} />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/customers" component={Customers} />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/platform" component={Platform} />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/announcements" component={Announcements} />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/recordings" component={Recordings} />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PrivateRoute path="/submissions" component={Submissions} />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PrivateRoute path={["/guide","/guides"]} component={Guides} />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <PrivateRoute exact path="/" component={Home} />
      </Suspense>

      <Route path="/signin" component={SignIn} />

      <ToastContainer autoClose={2000} />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  const { currentAccount, accounts } = state.auth;
  const index = accounts.findIndex((a) => a.id === currentAccount);
  return {
    currentAccount: index === -1 ? accounts[0] : accounts[index],
  };
};

export default connect(mapStateToProps)(AppMain);
