import axios from 'axios';
import { baseUrl, accountUrl } from '../config/constants';

export default {
  getAccounts: async () => {
    const res = await axios.get(`${baseUrl}/accounts`);
    return res.data;
  },
  getAccount: async (id) => {
    const res = await axios.get(`${baseUrl}/accounts/${id}`);
    return res.data;
  },
  getUsers: async (id) => {
    const res = await axios.get(`${baseUrl}/accounts/users`);
    return res.data;
  },
  updateRole: async (email, role) => {
    const res = await axios.post(`${baseUrl}/accounts/users`, { email, role });
    return res.data;
  },
  removeRole: async (email) => {
    const res = await axios.delete(`${baseUrl}/accounts/users?email=${email}`);
    return res.data;
  },
  setInvitation: async (data) => {
    const res = await axios.post(`${accountUrl}/invitations`, data);
    return res.data;
  },
};
