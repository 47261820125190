import axios from 'axios';
import { authUrl } from '../config/constants';

export default {
  signInUser: async ({email, password}) => {
    const res = await axios.post(`${authUrl}/sign_in`, {email, password});
    return res;
  },
  validateUser: async () => {
    const params = {
      'access-token': localStorage.getItem('access-token'),
      client: localStorage.getItem('client'),
      uid: localStorage.getItem('uid'),
    }
    const res = await axios.get(`${authUrl}/validate_token`, { params });

    // const json = localStorage.getItem('history');
    // const history = json ? JSON.parse(json) : []
    // history.push(res.headers['access-token']);
    // localStorage.setItem('history', JSON.stringify(history));

    localStorage.setItem('access-token', res.headers['access-token']);
    localStorage.setItem('client', res.headers['client']);
    localStorage.setItem('uid', res.headers['uid']);

    return res;
  }
};
