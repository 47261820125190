import React, { Component, Fragment } from 'react';
import Loader from 'react-loaders'

const Loading = ({}) => {
  return (
    <div className="loader-container">
      <div className="loader-container-inner">
        <div className="text-center">
          <Loader type="ball-grid-beat"/>
        </div>
      </div>
    </div>
  );
}

export default Loading;
