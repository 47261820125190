import { push } from "connected-react-router";
import authAPI from "../api/auth";
import accountsAPI from "../api/accounts";
import axios from "axios";

export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_START = "SIGN_IN_START";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";
export const SIGN_OUT = "SIGN_OUT";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const LOAD_ACCOUNTS = "LOAD_ACCOUNTS";
export const ADMIN_VIEW = "ADMIN_VIEW";

// redux-initial-state
// load access_token + currentAccount from localStorage
//  isSignedIn = access_token ? true : false
//  isLoading = true
//  currentAccount = currentAccount
//  set-axios defaults
// call validate_token
//  success: dispatch => valid-token user
//  failure: dispatch => sign-out

// valid-token action
// call accounts
//   success: dispatch => loadAccounts
//   error: sign-out

// sign-in action
// call sign-in(email, password)
//  success =>
//    storeToken to localStorage
//    dispatch => validate-token (user, accounts)
//  error =>
//    set-state: login-failure error message

// sign-out action
// call sign-out
//  success =>
//    delete token, currentAccount localStorage

const signInSuccess = (user, accounts, currentAccount) => ({
  type: SIGN_IN_SUCCESS,
  user,
  accounts,
  currentAccount,
});

const signInStart = () => ({
  type: SIGN_IN_START
});

const signInFailure = (errors) => ({
  type: SIGN_IN_FAILURE,
  errors: errors,
});

const saveSelectedAccount = (account) => {
  localStorage.setItem("currentAccount", account);
  axios.defaults.headers.common["account"] = account;
};

export const setAccount = (index) => {
  return (dispatch, getState) => {
    const { accounts } = getState().auth;
    const currentAccount = accounts[index];
    // console.log('SAVING: ', index, currentAccount);
    saveSelectedAccount(currentAccount.id);
    dispatch({ type: SET_ACCOUNT, currentAccount: currentAccount.id });
  };
};

export const signInUser = ({ email, password }) => {

  return (dispatch) => {
    dispatch(signInStart());
    authAPI
      .signInUser({ email, password })
      .then((res) => {
        // save headers to localStorage & axios
        if (res.errors) {
          dispatch(signInFailure(res.errors));
          return false;
        }
        accountsAPI
          .getAccounts()
          .then((accounts) => {
            const currentAccount = accounts[0];
            saveSelectedAccount(currentAccount.id);
            dispatch(signInSuccess(res.data, accounts, currentAccount.id));
            dispatch(push("/"));
          })
          .catch((err) => {
            if (err.response) {
              dispatch(signInFailure(err.response.data.errors));
            } else {
              console.log(err);
            }
          });
      }).catch((err) => {
        if (err.response) {
          dispatch(signInFailure(err.response.data.errors));
        } else {
          console.log(err);
        }
      });
  };
};

export const validateUser = () => {
  return (dispatch) => {
    authAPI
      .validateUser()
      .then((res) => {
        accountsAPI
          .getAccounts()
          .then((accounts) => {
            const currentAccount = +localStorage.getItem("currentAccount");
            const index = accounts.findIndex((e) => e.id === currentAccount);
            const account = accounts[index];
            console.log(currentAccount, index, account, accounts);
            saveSelectedAccount(account.id);
            dispatch(signInSuccess(res.data, accounts, account.id));
          })
          .catch((err) => {
            if (err.response) {
              dispatch(signInFailure(err.response.data.errors));
            } else {
              console.log(err);
            }
          });
      })
      .catch((err) => {
        if (err.response) {
          dispatch(signOutUser());
        } else {
          console.log(err);
        }
      });
  };
};

export const signOutUser = () => {
  return (dispatch) => {
    localStorage.removeItem("access-token");
    dispatch({
      type: SIGN_OUT
    });
    dispatch(push("/signin"));
  };
};

export const switchToAdminView = (adminView) => {
  return (dispatch) => {
    dispatch({
      type: ADMIN_VIEW,
      adminView
    });
  };
}

const initialState = {
  isSignedIn: false,
  currentUser: null,
  currentAccount: null,
  accounts: [],
  adminView: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SIGN_IN_START:
      return {
        ...state,
        isSignedIn: false,
        isLoading: true,
      }
    case SIGN_IN_SUCCESS:
      const { data } = action.user;
      return {
        ...state,
        isSignedIn: true,
        isLoading: false,
        currentUser: action.user,
        accounts: action.accounts,
        currentAccount: action.currentAccount,
        adminView: false//data.roles.includes('admin')
      };
    case SIGN_IN_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.errors,
      };
    case SET_ACCOUNT:
      return {
        ...state,
        currentAccount: action.currentAccount,
      };
    case SIGN_OUT:
      return {
        ...state,
        isSignedIn: false,
        isLoading: false,
        currentUser: undefined,
        currentAccount: undefined,
      };
    case ADMIN_VIEW:
        return {
          ...state,
          adminView: action.adminView
        }
    default:
      return state;
  }
}
