import React, { FC, Fragment } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { withRouter } from "react-router-dom";
import ResizeDetector from "react-resize-detector";
import AppMain from "../AppMain";

type TMain = {
  colorScheme?: any;
  enableFixedHeader?: boolean;
  enableFixedSidebar?: boolean;
  enableFixedFooter?: boolean;
  enableClosedSidebar?: boolean;
  closedSmallerSidebar?: boolean;
  enableMobileMenu?: boolean;
  enablePageTabsAlt?: boolean;
}

const Main: FC<TMain> = ({
  colorScheme,
  enableFixedHeader,
  enableFixedSidebar,
  enableFixedFooter,
  enableClosedSidebar,
  closedSmallerSidebar = false,
  enableMobileMenu,
  enablePageTabsAlt,
}) => {
  return (<ResizeDetector
    handleWidth
    render={({ width = 0 }) => (
        <div
          className={cx("app-container app-theme-" + colorScheme,
            { "fixed-header": enableFixedHeader },
            { "fixed-sidebar": enableFixedSidebar || width < 1250 },
            { "fixed-footer": enableFixedFooter },
            { "closed-sidebar": enableClosedSidebar || width < 1250 },
            {
              "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
            },
            { "sidebar-mobile-open": enableMobileMenu },
            { "body-tabs-shadow-btn": enablePageTabsAlt }
          )}
        >
          <AppMain />
        </div>
    )}
  />)
}

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default connect(mapStateToProp)(Main);
