import ThemeOptions from './ThemeOptions';
import Accounts from './Accounts';
import auth from './auth';
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  ThemeOptions,
  auth
});

export default createRootReducer;
