import React, { FC } from "react";
import { createRoot } from 'react-dom/client';
import * as serviceWorker from "./serviceWorker";

import { Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ConnectedRouter } from "connected-react-router";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "@uppy/core/dist/style.css";
// import "@uppy/dashboard/dist/style.css";
import "./assets/base.scss";
import Main from "Layout/MainPage";
import configureStore, { history } from "./config/configureStore";
import { Provider } from "react-redux";
import createInterceptors from "./config/token-interceptors";

const store = configureStore();
const interceptors = createInterceptors(store);
const root = createRoot(document.getElementById("root") as HTMLElement);

const App: FC = () => {
  return (<Provider store={store}>
    <ConnectedRouter history={history}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Main />
      </QueryParamProvider>
    </ConnectedRouter>
  </Provider>)
}

root.render(<App />);

declare const module: any;
if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept("./Layout/MainPage", App);
}

serviceWorker.unregister();